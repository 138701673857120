<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">厦门市司法局叶局长一行调研指导福建历思司法鉴定所</div>
      <p class="time">2018.12.07</p>
      <p class="space_content" style="text-indent:0">
        近日，厦门市司法局党组书记叶局长、办公室林副主任、司法鉴定仲裁管理处黄处长等领导莅临我所进行调研，参观了历思物证中心实验室并听取了福建历思司法鉴定所发展状况与环境资质申报情况汇报。
      </p>

      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/19-1.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/19-2.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        在厦门市司法局长期以来的关心、支持下，福建历思司法鉴定所获得了快速成长，专业优势与综合实力突出。特别在环境鉴定领域，我所是国内最早介入、最早研究、最早实践的机构之一，从理论到实践、从操作到标准，均有着深厚积累。2006年我所取得国内第一批环境物证鉴定资质；2016年成为环保部环境损害鉴定评估推荐机构。
      </p>
      <p class="space_content" style="text-indent:0">
        十多年来，承办了泉港皮革案等福建省重大特大和敏感环境案件，注重技术与法律的有机结合，做到鉴定专业化、规范化、合法化和证据化。2017年我所承担的《环境损害鉴定评估通用规范》福建省地方标准正式颁布，因目前国家还未出台正式的相关标准，该标准为国内该领域唯一的地方标准。2018年1月，环保部正式批准我所与漳州开发区联合申报的《环境证据化规范研究与应用试点》项目，并列入环保部环境损害鉴定评估技术试点。该试点为目前国内首个研究环境执法与司法衔接的环保部试点，也是福建省唯一的国家级环境损害鉴定评估技术试点。
      </p>
      <p class="space_content" style="text-indent:0">
        为了保障环境鉴定等业务的技术研发与服务能力，我所创建了第三方大型物证中心，拥有1500平方米的专业实验室，实验室洁净区达到万级标准，全面配置高端检验设备，具备了进行环境鉴定及跨专业综合研究与技术实施的良好基础条件。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/19-3.png')">
      </div>

      <p class="space_content" style="text-indent:0">
        叶局长充分肯定了我所在司法鉴定特别是环境鉴定领域所作的努力和取得的成绩，对我所宣传营销与创新发展工作进行指导，提出了许多宝贵的意见和建议。指出我所在宣传时，可以将一些生涩难懂的专业术语更换成通俗易懂的话语。例如将“环境物证”称为“环境损害鉴定”，将“法医物证鉴定”称为“亲子鉴定”，让表达的意思更直观明了。业务的名称内容尽量贴切民众生活与其拉近距离，真正让鉴定的相关知识深入人心。同时，也希望我所尽快完成环境鉴定资质重新登记的各项准备工作，司法局将给予重点支持。
      </p>
      <p class="space_content" style="text-indent:0">
        我所向叶局长表示，将不负所望，全力打造环境鉴定优势项目。依托领先的环境鉴定专业能力与综合运营管理能力，打造一个立足厦门、服务海西经济区、辐射全国的前沿型跨区域司法鉴定服务中心，争取成为厦门市司法鉴定行业的一张新名片。
      </p>



    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/18'"><p class="ellipsis1">厦门市公安局许标旗副局长一行莅临考察福建历思司法鉴定所</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/20'"><p class="ellipsis1">厦门市湖里区林重阳区长一行莅临考察福建历思司法鉴定所</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
